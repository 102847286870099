import axios from "axios";
import "./index.css";
import { useEffect, useState } from "react";

const MigrationServerItem = ({ row, date, getDailyForServer }) => {
	const handleClick = (event) => {
		event.preventDefault();
		getDailyForServer(date, row);
	};

	return (
		<div className="row-migration" onClick={handleClick}>
			<div className="migration-column">{row.url}</div>
			<div className="migration-column">{row.comment}</div>
			<div className="migration-column">{row.log_count}</div>
			<div className="migration-column">{row.from_server}</div>
		</div>
	);
};

const MigrationDayServerItem = ({ row, date, getDailyForServer }) => {
	return (
		<div className="row-migration">
			<div className="migration-column">{row.url}</div>
			<div className="migration-column">{row.comment}</div>
			<div className="migration-column">{row.log_count}</div>
			<div className="migration-column">{row.to_server}</div>
		</div>
	);
};

const MigrationDateItem = ({ data, getDailyForServer }) => {
	return (
		<div className="migration-date-item">
			<div className="migration-date-date">{new Date(data.date).toLocaleDateString()}</div>
			<div className="migration-date-servers">
				{data.migrations.map((migration) => {
					return <MigrationServerItem date={data.date} getDailyForServer={getDailyForServer} row={migration} />;
				})}
			</div>
		</div>
	);
};

export default function MigrationAnalytics() {
	const [weeklyData, setWeeklyData] = useState([]);

	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedServer, setSelectedServer] = useState(null);
	const [dailyMigrations, setDailyMigrations] = useState([]);

	const getDailyForServer = async (date, server) => {
		setSelectedDate(date);
		setSelectedServer(server);

		const request = await axios.post("https://dusk.masliukov.com/v4/migration/stats/day/vless", {
			server_id: server.from_server,
			date: date,
		});
		setDailyMigrations(request.data.migrations);
	};

	useEffect(() => {
		getWeekly();
	}, []);

	const getWeekly = async () => {
		const request = await axios.post("https://dusk.masliukov.com/v4/migration/stats/week/vless", {});
		console.log({ request: request.data });
		setWeeklyData(request.data.dates);
	};

	const handleOverlayClick = (event) => {
		if (event.target.id === "overlay") {
			setSelectedDate(null);
			setSelectedServer(null);
			setDailyMigrations([]);
		}
	};

	return (
		<div className="content-block t-container">
			<div className="t-content split">
				<div className="page-header">
					<h1>Аналитика Миграций</h1>
				</div>
				{dailyMigrations.length > 0 && (
					<>
						<div className="migrations-analytics-overlay-container" id="overlay" onClick={handleOverlayClick}>
							<div className="migrations-analytics-overlay-content">
								<h1>Миграция {new Date(selectedDate).toLocaleDateString()}</h1>

								<div style={{ marginTop: "24px", marginBottom: "24px" }}>
									Миграция с сервера {selectedServer.url} {selectedServer.comment} {selectedServer.from_server}
								</div>
								{dailyMigrations.map((dailyMigration) => {
									return <MigrationDayServerItem date={selectedDate} row={dailyMigration} />;
								})}
							</div>
						</div>
					</>
				)}
				<div className="migration-weekly-content">
					{weeklyData !== null &&
						weeklyData.map((data) => {
							return <MigrationDateItem getDailyForServer={getDailyForServer} data={data} />;
						})}
				</div>
			</div>
		</div>
	);
}
