import React, { Component, useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from "recharts";
import "../chart.css";
export default function MigrationsChart({ graphData }) {

    const [maxValue, setMaxValue] = useState(2500);


    useEffect(() => {
        let mV = 0;
        for (let i = 0; i < graphData.length; i++){
            if (parseInt(graphData[i].vless_count) > mV) { 
                mV = parseInt(graphData[i].vless_count)
            } 
            if (parseInt(graphData[i].shadowsocks_count) > mV) { 
                mV = parseInt( graphData[i].shadowsocks_count)
            }
        }
        console.log({mV})
        setMaxValue(mV);
    }, [graphData]);

    const isMobile = window.matchMedia("(max-width: 800px)").matches;

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className="custom-tooltip-date">{`${label}`}</div>
                    <div>
                        {payload.map((pld, idx) => (
                            <>
                                <div className="custom-tooltip-content">
                                    <div>{pld.dataKey === "vless_count" ? "Vless" : pld.dataKey === "shadowsocks_count" ? "Outline" : ""}</div>
                                    <div>
                                        {pld.value}
                                        {pld.dataKey === "total_revenue" && "₽"}{" "}
                                    </div>
                                </div>
                                <div>{idx === payload.length - 1 && "total_revenue" && "Date: " + pld.payload.created_at}</div>
                            </>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div className="charts-grid">
                
                <ResponsiveContainer className="custom-container">
                    <LineChart margin={{ top: 32, left: 32, right: 32, bottom: 32 }} width={isMobile ? 350 : 500} height={500} data={graphData}>
                        <YAxis hide={true} yAxisId="left-axis" domain={[0, maxValue]} orientation="left" dataKey="vless_count" />
                        <YAxis hide={true} yAxisId="right-axis" domain={[0, maxValue]} orientation="right" dataKey="shadowsocks_count" />
                        {/* <YAxis hide={true} yAxisId='left-axis' domain={[0, 900]} orientation='left' dataKey='expiring_keys_count' /> */}
                        <Line dot={false} yAxisId="right-axis" name="Migrations Vless: " type="monotone" dataKey="vless_count" stroke="#FFF" />
                        <Line dot={false} yAxisId="left-axis" name="Revenue: " type="monotone" dataKey="shadowsocks_count" stroke="#909090" />
                        <XAxis hide={true} name="OrderDate: " dataKey="to_char" />

                        <Tooltip content={<CustomTooltip />} />
                    </LineChart>
                </ResponsiveContainer>
                
            </div>
        </>
    );
}
