import { Link, BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function Navigation({ status }) {
	const [isNavigation, setNavigation] = useState(true);

	const { pathname } = useLocation();

	useEffect(() => {
		setNavigation(false); // Close the navigation panel
	}, [pathname]);

	return (
		<>
			<div
				className="nav-btn"
				onClick={() => {
					setNavigation(!isNavigation);
				}}
			>
				💶
			</div>
			<div>
				{isNavigation ? (
					<div className="common-navigation">
						<div className="navigation-header">Outline Bot</div>
						{status === "admin" && (
							<>
								<Link to="/" className="navigation-link">
									Dashboard
								</Link>
								<Link className="navigation-link" to="/migration">
									Group Migration [ Outline ]
								</Link>
								<Link className="navigation-link" to="/migration-vless">
									Group Migration [ Vless ]
								</Link>
								<Link to="/key" className="navigation-link">
									Key Manager
								</Link>

								<Link to="/user" className="navigation-link">
									User Manager
								</Link>
								<Link to="/create-deeplink" className="navigation-link">
									Deeplinks [ Outline ]
								</Link>
								<Link to="/create-deeplink-vless" className="navigation-link">
									Deeplinks [ Vless ]
								</Link>
								<Link to="/campaigns" className="navigation-link">
									Campaigns
								</Link>
								<Link className="navigation-link" to="/settings-outline">
									Outline Servers
								</Link>
								<Link className="navigation-link" to="/settings-vless">
									Vless Servers
								</Link>
								<Link className="navigation-link" to="/proxies">
									Proxies
								</Link>
								<Link className="navigation-link" to="/migration-analytics">
									Migration Analytics [ Vless ]
								</Link>
								<Link className="navigation-link" to="migration-analytics-outline">
								Migration Analytics [ Outline ]
								</Link>
								<Link className="navigation-link" to="/send-message">
									Send Messages [ Outline ]
								</Link>
							</>
						)}
						{status === "moderator" && (
							<>
								<Link to="/create-deeplink" className="navigation-link">
									Deeplinks [ Outline ]
								</Link>
								<Link to="/create-deeplink-vless" className="navigation-link">
									Deeplinks [ Vless ]
								</Link>
								<Link to="/key" className="navigation-link">
									Key Manager
								</Link>

								<Link to="/user" className="navigation-link">
									User Manager
								</Link>
								<Link className="navigation-link" to="/migration">
									Group Migration [ Outline ]
								</Link>
								<Link className="navigation-link" to="/migration-vless">
									Group Migration [ Vless ]
								</Link>
								<Link to="/campaigns" className="navigation-link">
									Campaigns
								</Link>
								<Link className="navigation-link" to="/migration-analytics">
									Migration Analytics [ Vless ]
								</Link>
								<Link className="navigation-link" to="migration-analytics-outline">
								Migration Analytics [ Outline ] 
								</Link>
								<Link className="navigation-link" to="/send-message">
									Send Messages [ Outline ]
								</Link>
							</>
						)}
						{status === "system-manager" && (
							<>
								<Link to="/" className="navigation-link">
									Default
								</Link>
								<Link className="navigation-link" to="/migration">
									Group Migration [ Outline ]
								</Link>
								<Link className="navigation-link" to="/migration-vless">
									Group Migration [ Vless ]
								</Link>
								<Link to="/key" className="navigation-link">
									Key Manager
								</Link>

								<Link to="/user" className="navigation-link">
									User Manager
								</Link>

								<Link to="/create-deeplink" className="navigation-link">
									Deeplinks [ Outline ]
								</Link>
								<Link to="/create-deeplink-vless" className="navigation-link">
									Deeplinks [ Vless ]
								</Link>
								<Link to="/campaigns" className="navigation-link">
									Campaigns
								</Link>
								<Link className="navigation-link" to="/settings-outline">
									Outline Servers
								</Link>
								<Link className="navigation-link" to="/settings-vless">
									Vless Servers
								</Link>
								<Link className="navigation-link" to="/proxies">
									Proxies [ Outline ]
								</Link>
								<Link className="navigation-link" to="/migration-analytics">
									Migration Analytics [ Vless ]
								</Link>
								<Link className="navigation-link" to="migration-analytics-outline">
								Migration Analytics [ Outline ]
								</Link>
								<Link className="navigation-link" to="/send-message">
									Send Messages [ Outline ]
								</Link>
							</>
						)}
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
}
